import $ from 'jquery';
import * as NProgress from 'nprogress';
import 'nprogress/nprogress.css';

window.NProgress = NProgress;

window.NProgress.configure({
                      parent: '#top-navbar',
                      showSpinner: false,
                      template: `<div class="bar" role="bar" style="background: rgb(0 94 255);"><div class="peg"></div></div>`,
                    });

$.ajaxSettings.beforeSend = function() {
  window.NProgress.start();
};
$.ajaxSettings.complete = function() {
  window.NProgress.done();
};

// On page navigation, start the progress bar to indicate that a navigation is initiated.
// Use feature detection to make use of the Navigation API else fallback to default behavior
if ('navigation' in window) {
  window.navigation.addEventListener('navigate', (event) => {
    window.NProgress.start();
  });
} else {
  document.querySelectorAll('a')
      .forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
          window.NProgress.start();
        });
      });
}


